import { ApolloClient, ApolloLink, FieldPolicy, HttpLink, InMemoryCache, concat } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { getMainDefinition, offsetLimitPagination } from '@apollo/client/utilities';
import { split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { persistCache } from 'apollo3-cache-persist';
import { uncrunch } from 'graphql-crunch';
import 'isomorphic-fetch';
import jwtDecode from 'jwt-decode';
import React from 'react';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import Notifier from '../Notifier';
import ErrorNotification from '../components/Shared/ErrorNotification';
import { RefreshToken } from '../queries';
import { reactiveFields } from '../reactive';
import AppStorage from './AppStorage';
import { logout } from './auth';
import config from './config';
import { errorCodes, errorDetails } from './constants/errors';
import { getKeys } from './helpers';

const inWindow = typeof window !== 'undefined';
const isProd = config.ENV === 'production';

const subClient = inWindow
  ? new SubscriptionClient(config.SUB_API_URL, {
      reconnect: true
    })
  : null;
const AppStorageMock = { getItem: () => '{}', setItem: () => {} };
const httpLink = new HttpLink({ uri: config.API_URL });
const uncruncher = new ApolloLink((operation, forward) =>
  forward(operation).map(response => {
    if (response.data && isProd) {
      response.data = uncrunch(response.data);
    }
    return response;
  })
);
const inflatedHttpLink = concat(uncruncher, httpLink);
const wsLink = inWindow ? new WebSocketLink(subClient) : null;
const splitLink = inWindow
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      inflatedHttpLink
    )
  : null;

let lastRefreshTime = 0,
  isRefreshing = false,
  isConnected = true;

if (inWindow) {
  window.addEventListener('online', updateOnlineStatus);
  window.addEventListener('offline', updateOnlineStatus);
}

function updateOnlineStatus(event) {
  isConnected = navigator.onLine;
  if (isConnected) {
    Notifier.success({ title: 'Network Status', message: 'Back online' });
  } else {
    Notifier.error({
      width: 320,
      container: 'bottom-left',
      content: () => <ErrorNotification title={'Network error'} description={'Please check your internet connection.'} />
    });
  }
}

export function isTokenExpired(token: string) {
  const { exp } = jwtDecode(token);
  // console.log(Math.floor(Date.now() / 1000) - (exp - 5))
  return Math.floor(Date.now() / 1000) >= exp - 5; //3580
}

export async function newRefreshToken(client: ApolloClient<any>, tokens: { refreshToken: string }) {
  lastRefreshTime = Date.now();
  const newTokens = await client.mutate({
    mutation: RefreshToken,
    variables: {
      refreshToken: tokens.refreshToken,
      clientId: config.CLIENT_ID,
      slotsv2: true,
      ...(isProd ? { 'x-deduplication': 'true' } : {})
    }
  });
  return newTokens;
}

const authLink = setContext(request => {
  return new Promise(async resolve => {
    if (!isConnected) {
      await retryAfter(500);
    }
    let tokens = AppStorage.get('tokens');
    const providerBranchId = AppStorage.get('providerBranchId');
    const name = request.operationName;
    const withinRefreshTime = () => lastRefreshTime < Date.now() - 120000;
    const needRefreshToken = tokens => tokens && isTokenExpired(tokens.token) && name !== 'refreshTokenBusUser' && withinRefreshTime();
    if (needRefreshToken(tokens)) {
      try {
        console.log('refreshing token');
        console.log('tokens:', tokens);
        console.log('isRefreshing:', isRefreshing);
        isRefreshing = true;
        const {
          data: {
            refreshTokenBusUser: { token, refreshToken }
          }
        } = await newRefreshToken(client, tokens);

        console.log('new token: ', token);
        AppStorage.set('tokens', { token, refreshToken });
        tokens = { token, refreshToken, firebaseToken: tokens.firebaseToken };
        isRefreshing = false;
      } catch (e) {
        isRefreshing = false;
        if (e && e.message.includes('Network error')) {
          return Notifier.error({
            width: 320,
            container: 'bottom-left',
            content: () => <ErrorNotification title={'Network error'} description={'Please check your internet connection.'} />
          });
        } else if (e?.graphQLErrors?.[0]?.message === 'Unauthenticated' || e?.graphQLErrors?.[0]?.message === 'Wrong refresh token') {
          console.warn('about to log out');
          logout();
        }
        console.log(JSON.stringify(e));
      }
    } else if (isRefreshing && name !== 'refreshTokenBusUser') {
      await retryAfter(500, needRefreshToken);
      tokens = AppStorage.get('tokens');
    }

    return resolve({
      headers: {
        providerbranchid: providerBranchId,
        authorization: tokens ? `Bearer ${tokens.token}` : null,
        clientid: config.CLIENT_ID,
        slotsv2: true,
        ...(isProd ? { 'x-deduplication': 'true' } : {})
      }
    });
  });
});

const retryLink = inWindow
  ? new RetryLink({
      delay: {
        initial: 5000,
        max: Infinity,
        jitter: true
      },
      attempts: {
        max: 1,
        retryIf: (error, _operation) => !!error
      }
    })
  : null;

const errorLink = onError(error => {
  let message = 'Something went wrong!';
  let description = '';
  try {
    message = String(error?.networkError?.result?.errors?.[0]?.message || error?.response?.errors?.[0]?.message || error?.networkError?.message || error?.graphQLErrors?.[0]?.message).replace(
      'Error:',
      ''
    );
  } catch (e) {}
  if (message.includes('too many connections') || message.includes('429')) {
    message = 'We are experiencing heavy traffic please try again after some time.';
  }
  if (
    message === 'Unauthenticated' ||
    message === 'Wrong refresh token' ||
    message.includes('JSON Parse error') ||
    message.includes('Unexpected token < in JSON') ||
    [errorCodes.BRANCH_APP_USER_ALREADY_ADDED, errorCodes.APP_USER_ALREADY_EXISTS, errorCodes.UNKNOWN_ERROR].includes(error?.graphQLErrors?.[0]?.code)
  ) {
    if (!message.includes('JSON Parse error') && !message.includes('Unexpected token < in JSON')) {
      description = message;
    }
    message = errorDetails[errorCodes.UNKNOWN_ERROR].message;
  }
  Notifier.error({ width: 320, container: 'bottom-left', content: () => <ErrorNotification title={message} description={description} /> });
});

type KeyArgs = Parameters<typeof offsetLimitPagination>[0];
type MemoryField = { fields?: string[]; keyArgs?: KeyArgs };
const customOffsetLimitPagination = (memoryField: MemoryField) => {
  const keyArgs = memoryField?.keyArgs;
  return {
    ...offsetLimitPagination(keyArgs),
    merge: (...mergeArgs: Parameters<FieldPolicy['merge']>) => {
      const [existing, incoming, { args }] = mergeArgs;
      const { offset } = args as { offset: number };
      if (offset === 0) {
        return incoming;
      }
      const hasFields = !!memoryField?.fields && !!memoryField.fields.length;
      if (!hasFields) {
        return offsetLimitPagination(keyArgs)?.merge?.(...mergeArgs);
      }
      const merged = memoryField?.fields.reduce((acc, key) => {
        const merged = offsetLimitPagination(keyArgs)?.merge?.(existing?.[key], incoming?.[key], { args });
        return { ...acc, [key]: merged };
      }, {});
      return merged;
    }
  };
};

const memoryFields = {
  getBranchAppointments: {
    fields: undefined,
    keyArgs: ['GetBranchAppointmentsInput']
  },
  getBranchOrderSubscriptions: {
    fields: undefined,
    keyArgs: ['GetBranchOrderSubscriptionsInput']
  },
  getProducts: {
    fields: undefined,
    keyArgs: ['where']
  },
  getBranchPets: {
    fields: undefined,
    keyArgs: ['GetBranchPetsInput']
  },
  getBranchOrders: {
    fields: undefined,
    keyArgs: ['GetBranchOrdersInput']
  },
  getBranchChatRooms: {
    fields: undefined,
    keyArgs: ['GetBranchChatRoomsInput']
  },
  getMessages: {
    fields: undefined,
    keyArgs: ['GetMessagesInput']
  },
  getMedCondsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetMedCondsByPetRecordIdInput']
  },
  getNotesByMedCondId: {
    fields: undefined,
    keyArgs: ['GetNotesByMedCondIdInput']
  },
  getGroomingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetGroomingReportsByPetRecordIdInput']
  },
  getNotesByGroomingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByGroomingReportIdInput']
  },
  getTrainingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetTrainingReportsByPetRecordIdInput']
  },
  getNotesByTrainingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByTrainingReportIdInput']
  },
  getWalkingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetWalkingReportsByPetRecordIdInput']
  },
  getNotesByWalkingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByWalkingReportIdInput']
  },
  getSittingReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetSittingReportsByPetRecordIdInput']
  },
  getNotesBySittingReportId: {
    fields: undefined,
    keyArgs: ['GetNotesBySittingReportIdInput']
  },
  getDaycareReportsByPetRecordId: {
    fields: undefined,
    keyArgs: ['GetDaycareReportsByPetRecordIdInput']
  },
  getNotesByDaycareReportId: {
    fields: undefined,
    keyArgs: ['GetNotesByDaycareReportIdInput']
  },
  branchMessageTemplateGet: {
    fields: undefined,
    keyArgs: ['where']
  },
  getBranchAppointmentTags: {
    fields: undefined,
    keyArgs: ['GetBranchAppointmentTagsInput']
  },
  getBranchPetRecordTags: {
    fields: undefined,
    keyArgs: ['GetBranchPetRecordTagsInput']
  },
  getBranchAppUserTags: {
    fields: undefined,
    keyArgs: ['GetBranchAppUserTagsInput']
  },
  getBranchAppUsers: {
    fields: undefined,
    keyArgs: ['GetBranchAppUsersInput']
  },
  getBranchOrderInvoices: {
    fields: undefined,
    keyArgs: ['GetBranchOrderInvoicesInput']
  },
  getAppUserBranchCreditTransactionsByAppUserId: {
    fields: undefined,
    keyArgs: ['GetAppUserBranchCreditTransactionsByAppUserIdInput']
  },
  getBranchTags: {
    fields: ['branchPetRecordTags', 'branchAppointmentTags', 'branchAppUserTags'],
    keyArgs: ['where']
  },
  getBranchFormsByPetRecordId: {
    fields: ['answeredForms', 'nonAnsweredForms'],
    keyArgs: ['GetBranchFormsByPetRecordIdInput', 'where']
  },
  getPharmaItems: {
    fields: undefined,
    keyArgs: ['GetPharmaItemsInput']
  },
  getPetRecordRecords: {
    fields: ['VaccRecords'],
    keyArgs: ['GetPetRecordRecordsInput']
  },
  getBranchOrderSubscriptionRepeats: {
    fields: undefined,
    keyArgs: ['GetBranchOrderSubscriptionRepeatsInput']
  },
  getBranchSubmittedForms: {
    fields: undefined,
    keyArgs: ['GetBranchSubmittedFormsInput']
  },
  getBranchDiscounts: {
    fields: undefined,
    keyArgs: ['GetBranchDiscountsInput']
  },
  getBranchBusUsers: {
    fields: undefined,
    keyArgs: ['GetBranchBusUsersInput']
  },
  getBranchPayouts: {
    fields: undefined,
    keyArgs: ['limit']
  },
  getBranchBalanceTransactions: {
    fields: undefined,
    keyArgs: ['limit']
  }
};

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...reactiveFields,
        ...getKeys(memoryFields).reduce((acc, key) => {
          const memoryField = memoryFields[key];
          return {
            ...acc,
            [key]: customOffsetLimitPagination(memoryField)
          };
        }, {})
      }
    }
  }
});

persistCache({
  cache,
  storage: inWindow ? AppStorage : AppStorageMock,
  maxSize: 262144
});

const client = new ApolloClient({
  cache,
  link: inWindow ? errorLink.concat(retryLink.concat(authLink.concat(splitLink))) : inflatedHttpLink
});

export default client;

const retryAfter = (seconds: number, needRefreshToken = () => false) => {
  console.log('--- Applying Expiremantal Retry After Algo ---');
  return new Promise(resume => {
    const interval = setInterval(async () => {
      let tokens = AppStorage.get('tokens');
      if (isConnected && !isRefreshing && !needRefreshToken(tokens)) {
        clearInterval(interval);
        resume();
      }
    }, seconds);
  });
};
